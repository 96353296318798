<template>
  <div class="code-container">
    <div class="code-text" v-if="!this.$route.query.ban">
      <p>设备激活</p>
      Equipment activation
    </div>
    <div>
      <img src="../../../images/image-active.png" alt=" " class="code-container-image" v-if="!this.$route.query.ban">
      <img src="../../../images/no-active.png" alt=" " class="code-container-image" v-else>
    </div>
    <div class="form-container" v-if="!this.$route.query.ban">
      <van-field placeholder="请输入序列号" class="input-type" v-model.trim="code"/>
      <van-button type="primary" class="button-type" @click="activationCode">激活</van-button>
    </div>
    <div v-else class="code-msg">
      {{msg}}
    </div>
  </div>
</template>
<script>
  import { mapActions , mapMutations , mapState } from 'vuex';
  export default {
    name: "codes",
    computed:{
      code:{
          get() { return this.$store.state.code.code },
          set(val){this.change({
              key:'code',
              value:val
          });}
      },
      ...mapState('code',['data']),
      ...mapState('device',['msg']),
    },
    methods:{
      //激活
      activationCode(){
        this.axiosData({
          data:{
            mac:this.$route.query.mac.toString()
          },
          _this:this
        });
      },
      ...mapMutations('code',['change']),
      ...mapActions('code',['axiosData']),
    }
  };
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import 'code';
</style>
